import React, { useRef } from "react";
import Navbar from "../../comman/header/navbar/Navbar";
import { useState, useEffect, useMemo } from "react";
import Header from "../../comman/header/header/Header";
import {} from "react-bootstrap";
import axios from "axios";
import "./User.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../../services/WebUrls";
import { useNavigate, Link } from "react-router-dom";
import Pagination from "../../comman/pagination/Pagination.jsx";
import "../../comman/pagination/Pagination.css";

import Button from 'react-bootstrap/Button';


export default function User() {
  const [user, setUser] = useState([]);

  const [closeModal, setCloseModal] = useState(false);
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [filterVal, setFilterVal] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [subscriptiondata, setSubscriptiondata] = useState();
  const [hide, setHide] = useState(false);
  const [Toatalpage, setToatalpage] = useState([1, 2, 3]);
  const [record, setRecord] = useState([]);
  const [fetchdata, setFetchdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10);

  const navigate = useNavigate();

  const getFetchData = async () => {
    try {
      const response = await axios.get(baseUrl.User_list);
     
      setFetchdata(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    getFetchData();
  }, []);

  const filteredData = fetchdata.filter((row) =>
  {
    
   return row?.row?.name?.toLowerCase().includes(filterVal?.toLowerCase())
  }
  );
   
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const offset = currentPage * perPage;
  const paginatedData = filteredData.slice(offset, offset + perPage);

       
  const handleDelete = async (userId) => {
    try {
      const response = await axios.delete(`${baseUrl.userdelete}/${userId}`);
      // Handle success response
     
      // Refresh the data after deletion
      getFetchData();
      // Show success notification using toast
      toast.success(response.data.message);
    } catch (error) {
      // Handle error
      console.error("Error deleting user:", error.message);
      // Show error notification using toast
      toast.error("Failed to delete user");
    }
  };

  return (
    <>
      <div>
        <div style={{ marginTop: "10px" }}>
          <Header />
          <Navbar />
        </div>
        <ToastContainer />
        <div id="page-wrapper">
          <div
            className="col-lg-12 col-sm-12 col-xs-12"
            style={{ marginTop: "20px" }}
          >
            <div className="table-responsive" style={{ margin: "1px" }}>
              <div className="title">
                <div>User</div>
                <div>
                  <span>
                    <Link to="/dashboard"> Dashboard</Link>
                  </span>
                  <span> / User </span>
                </div>
              </div>
              <hr></hr>
              <div>
                <div
                  className="table-responsive panel white-box radius"
                  style={{ height: "100%" }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel-body">
                        <div className="header-content">
                          <div className="col-md-3">
                            <div className="form-group">
                              <div className="input-icons">
                                <i className="fa fa-search icon"></i>
                                <input
                                  type="search"
                                  value={filterVal}
                                  placeholder="search..."
                                  className="form-control"
                                  onChange={(e) => setFilterVal(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table table-bordered  table-hover manage-u-table">
                    <thead>
                      <tr
                        className="table_th_font thBack"
                        style={{ backgroundColor: "#F08000" }}
                      >
                        <th scope="col" className="text-center">
                          S.No
                        </th>
                        <th scope="col" className="text-center">
                          Name
                        </th>
                        <th scope="col" className="text-center">
                          Contact Number
                        </th>
                        <th scope="col" className="text-center">
                          Email
                        </th>
                        <th scope="col" className="text-center">
                          Country Code
                        </th>

                        {/* <th scope="col" className="text-center">
                          Action
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {console.log(paginatedData,"data++++++++")} */}
                      {paginatedData.map((row, index) => (
                      
                        <tr key={index} className="table_th_font bg-light thBack">
                          <td scope="col" className="text-center text-dark">
                            {currentPage * perPage + index + 1}
                          </td>
                          <td scope="col" className="text-center text-dark">
                            {row?.row?.name}
                          </td>
                          <td scope="col" className="text-center text-dark">
                            {row?.row?.contact_number}
                          </td>
                          <td scope="col" className="text-center text-dark">
                            {row?.row?.email}
                          </td>
                          <td scope="col" className="text-center text-dark">
                            {row?.row?.country_code}
                          </td>

                           {/* <td scope="col" className="text-center text-dark "> */}
                           {/* <Button onClick={() => handleDelete(row.row._id)}  variant="primary" style={{ backgroundColor: '#00bac2' }}>Delete</Button> */}
                          {/* </td>  */}


                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(filteredData.length / perPage)}
                    handlePageClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
